import {RESOLUTIONS} from './constant';

function getWindServerUrl() {
    const PROD_WIND_URL = 'https://wind-map-server.herokuapp.com/wind';

    const LOCAL_WIND_URL = '/wind'; // if the API server is serving both frontend & wind data
    if (window && window.location && window.location.host) {
        const {host} = window.location;
        if (host.includes('localhost') || host.includes('127.0.0.1')) {
            return LOCAL_WIND_URL;
        }
    }

    return PROD_WIND_URL;
    // return LOCAL_WIND_URL;
}

export async function fetchWind(bounds) {    
    try {
        const WIND_URL = getWindServerUrl();
        // console.log(WIND_URL);

        const resp = await fetch(WIND_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                res: RESOLUTIONS.RES_1P00, 
                bounds
                // bounds: [[0,0], [359,180]] // full range for 1p00 resolution
            })
        });

        const payload = await resp.json();
        // console.log(payload);
        return payload;

    } catch (e) {
        console.log('#fetchWind');
        console.error(e);
    }

    return null;
}

