/*
 GFS compatible resolutions of wind data to be fetched from Backend API
*/
export const RES_1P00 = '1p00';
export const RES_0P50 = '0p50';
export const RES_OP25 = '0p25';

export const RESOLUTIONS = {
    RES_1P00, 
    RES_0P50, 
    RES_OP25
};


/* 
important data values' corresponding indexes in array. listed as constants to avoid bugs
*/
export const I_X1 = 0;
export const I_Y1 = 1;
export const I_DIRECTION_RADIAN = 2;
export const I_U = 3;
export const I_V = 4;
export const I_NEW_X = 5;
export const I_NEW_Y = 6;


/**
animation controlling constants
*/
// TODO: increase distance to cover according to increase in zoom
export const DISTANCE_TO_COVER = 15; // probably in pixels
// const TRAVEL_TIME_COEFFICIENT = 1250; // arbitrary number, whichever makes animation look good

/*
 DISTANCE_FACTOR doesnt necessarily represent distance in pixels
 you can think of it roughly as 
 DISTANCE_FACTOR = DISTANCE_TO_COVER * TRAVEL_TIME_COEFFICIENT;
*/
// export const DISTANCE_FACTOR = DISTANCE_TO_COVER * 1000; // 

/*
zoom
*/
export const MAPBOX_MIN_ZOOM = 3;
export const MAPBOX_MAX_ZOOM = 8;
// const GMAP_MIN_ZOOM = some value;
// const GMAP_MAX_ZOOM = some value;
// based on the map type used, change
export const MIN_ZOOM = MAPBOX_MIN_ZOOM;
export const MAX_ZOOM = MAPBOX_MAX_ZOOM;

/*
0 to not show grid, 1 grid size means show grid after every 1 deg of lon, lat
*/
export const GRID_STEP_SIZE = 10;

export const NUM_PRECOMPUTED_GRADIENTS = 20; // color gradients

export const PARTICLE_LENGTH = 12; // used in width parameter of functions
export const PARTICLE_HEIGHT = 3; 
