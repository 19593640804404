import {RES_1P00, RES_0P50, RES_OP25, DISTANCE_TO_COVER, DISTANCE_FACTOR} from './constant';


// convert degrees to Radian
// const toRadian = deg => deg * Math.PI/180;

// convert Radian to degrees
// const toDegree = rad => rad * 180/Math.PI;

// const calcX2 = (dist, radian) => dist * Math.cos(radian);
// const calcY2 = (dist, radian) => dist * Math.sin(radian);
const calcDX = (dist, radian) => dist * Math.cos(radian);
const calcDY = (dist, radian) => dist * Math.sin(radian);

// unused
// const getRand = (min, max) => min + Math.ceil(Math.random() * Math.abs(max - min));
// const mirror = t => (t < .5 ? 2 * t : 2 - 2 * t);



/*
takes lonIdx,latIdx and converts to lon,lat (Mapbox / Google Map compatible)

At the moment, converts 0,0 to -180,-90
*/
const arrayCoordsToRealCoords = (res, lonIdx, latIdx) => {

    let lon; let lat;

    /*
    convert lonIdx,latIdx which are corresponding array indexes for real world lon,lat
    into real world lon,lat
    */
    if (res === RES_1P00) {
        /*
        lon according to GFS is 0 to 359 continous, while for mapbox is -180 (we'll use -179) to 180
            gfs_lon: 0 to 180 equals mapbox_lon: 0 to 180
            gfs_lon: 181 to 359 equals -179 to 0
        */
        if (lonIdx >= 0 && lonIdx <= 360) lon = lonIdx <= 180? lonIdx: lonIdx - 359;

        // following is probably buggy
        // if (lonIdx >= 0 && lonIdx <= 360) lon = lonIdx -180;

        if (latIdx >= 0 && latIdx <= 180) lat = latIdx - 90;
    }
    else if (res === RES_0P50) {

        /*
        0   1   2   3   4   5   6...
        0   0.5 1   1.5 2   2.5 3...

        ...360     361         362     363     364
        ...180     180.5       181     181.5   182
        ...180    -178.5      -178    -177.5  -177

        361 / 2 - 359 == -178.5
        lon = lonIdx / 2 - 359
        */
        if (lonIdx >= 0 && lonIdx <= 720) lon = lonIdx <= 360? lonIdx / 2: lonIdx / 2 - 359;

        // probably buggy
        // if (lonIdx >= 0 && lonIdx <= 720) lon = lonIdx / 2 - 180;
        
        if (latIdx >= 0 && latIdx <= 360) lat = latIdx / 2 - 90;
    }
    else if (res === RES_OP25) {

        if (lonIdx >= 0 && lonIdx <= 1440) lon = lonIdx <= 720 ? lonIdx / 4 : lonIdx / 4 - 359;

        // probably buggy
        // if (lonIdx >= 0 && lonIdx <= 1440) lon = lonIdx / 4 - 180;

        if (latIdx >= 0 && latIdx <= 720) lat = latIdx / 4 - 90;
    } else {
        console.error('#arrayCoordsToRealCoords: Wrong GFS resolution or coordinates', res, lonIdx, latIdx);
    }

    return [lon, lat];
}


/*
lon,lat to lonIdx,latIdx
-180,-90 becomes 0,0
*/
// const realCoordsToArrayCoords = (res, lon, lat) => {
//     let lonIdx; let latIdx;

//     if (lon >= -180 && lon <= 180 && lat >= -90 && lat <= 90) {
//         if (res === RES_1P00) {
//             lonIdx = lon + 180;
//             latIdx = lat + 90;
//         }
//         else if (res === RES_0P50) {
//             lonIdx = (lon + 180) * 2;
//             latIdx = (lat + 90) * 2;
//         }
//         else if (res === RES_OP25) {
//             lonIdx = (lon + 180) * 4;
//             latIdx = (lat + 90) * 4;
//         } 
//     } else {
//         console.error('#realCoordsToArrayCoords: Wrong GFS resolution or coordinates:', lon, lat);
//     }

//     return [lonIdx, latIdx];
// }


/*
convert array coordinates to real world coordinates 0,0 -> -180,-90

dataRows is array of arrays of
    [lonIdx, latIdx, u, v]

returns array of arrays of following format
    [lon, lat, u, v]
*/
export const transformToLonLat = (dataRows, res) => {

    // eslint-disable-next-line prefer-const
    let transformedRows = [];

    for (let i = 0; i < dataRows.length; i++) {
        const [lon, lat] = arrayCoordsToRealCoords(res, dataRows[i][0], dataRows[i][1]);
       
        transformedRows.push([
            lon,
            lat,
            dataRows[i][2], // u
            dataRows[i][3] // v
        ]);
    }

    return transformedRows;
}


/*
filters and returns data that lies in provided bounds
also 
calculates wind features like
    x1,y1 (starting point)
    travelTime
    direction in Radian
    x2,y2 (end point)
 */
export function filterAndCalcParticleFeatures(map, bounds) {

    /*
    Project geojson coordinate to the map's current state

    (from lon,lat get x,y on screen)

    usage example:
    const d = [lon, lat];
    x = projectToXY(d).x;
    y = projectToXY(d).y;

    docs: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#project
    When the map is pitched and lnglat is completely behind the camera,
    there are no pixel coordinates corresponding to that location. In that case,
    the x and y components of the returned Point are set to Number.MAX_VALUE.

    To avoid naming conflicts with other libs, avoid using simply 'project' as name of the function
    */
    // const projectToXY = coords => map.project(new mapboxgl.LngLat(coords[0], coords[1]));
    const projectToXY = coords => map.project(coords);



    const [[sw_lon, sw_lat], [ne_lon, ne_lat]] = bounds;

    let filteredParticles = [];

    for (let i = 0; i < globalThis.processedData.length; i++) {
        const row = globalThis.processedData[i];

        // skip most particles 
        if (
            i % 2 === 0 
            || 
            i % 3 === 0 
            || 
            i % 5 === 0 
            || 
            i % 7 === 0 
            || 
            i % 11 === 0 
            || 
            i % 13 === 0 
            ) continue;


        const lon   = row[0];
        const lat   = row[1];


        // lon is actually -180 to 180
        if (lon >= sw_lon && lon <= ne_lon  && lat >= sw_lat && lat <= ne_lat) {

            /*
            exclude the particles whose coords are not visible on screen.
            when projected, their x,y pixel values are Number.MAX_VALUE (set by mapbox sdk)
            */
            if (!row.includes(Number.MAX_VALUE)) {
            
                /*
                calculate particle features
                */

                const onScreenCoords = projectToXY([lon, lat]);
                let x1 = onScreenCoords.x;
                let y1 = onScreenCoords.y;
                
                const u     = row[2] * 0.05;
                const v     = row[3] * 0.05;


                /*
                direction angle calculation
                u-component of wind is row[2] & v-component is row[3]

                TODO is this valid assumption? 
                IMPORTANT: Flip the real world plane (with counter-clockwise angles) upside down (to clockwise angles)
                    do this by change sign of the angle.
                    in maths, angles are counter-clockwise
                    in computer graphics, they are clockwise (y-axis increases downwards)???

                    const dirRadian = - Math.atan(row[3] / row[2]);
                */
                // const dirRadian = Math.atan(row[3] / row[2]);
                // TODO: CHANGE FRAME OF REFERENCE OF CANVAS


                const dirRadian = Math.atan2(v, u);
                 // subtract from 360 degrees
                // const dirRadian = - Math.atan(v / u);
                // const dirRadian = 2 * Math.PI - Math.atan(v / u);
                // console.log('direction angle:', dirRadian);

                // for canvas, we need absolute x2, y2 on the screen. Unlike svg which wants relative difference from x1,y1 

                // previously, we were calculating absolute x2 value, but only the delta can work too
                // let x2 = x1 + calcX2(DISTANCE_TO_COVER, dirRadian);
                // let y2 = y1 + calcY2(DISTANCE_TO_COVER, dirRadian);


                /*
                todo: Answer these questions
                1. should dx & dy be positive? what if they are negative?
                2. if dx is negative, than x1+dx (in drawWind) will decrease x1. 
                    but we have already tilted the frame of reference by dirRadian
                    so wouldn't dx being negative cancel out the effect for frame of reference tilting?

                    we need to tilt the frame of reference to show the face & direction of particle. but how should dx,dy be postivies or anything?
                */
                // let dx = calcDX(DISTANCE_TO_COVER, dirRadian);
                // let dy = calcDY(DISTANCE_TO_COVER, dirRadian);

                // round them to int
                x1 = (x1 + 0.5) | 0;
                y1 = (y1 + 0.5) | 0;

                /*
                instead of eculidean distance, use manhattan distance cause it is fast to calcualte
                */
                // const travelTime = Math.min(3000, travelTimeCoefficient * distanceToCover / (Math.abs(row[2]) + Math.abs(row[3])));
                // const travelTime = TRAVEL_TIME_COEFFICIENT * DISTANCE_TO_COVER / (Math.abs(row[2]) + Math.abs(row[3]));
                // let velocity = (Math.abs(row[2]) + Math.abs(row[3]));
                // const velocity = Math.sqrt( row[2]**2 + row[3]**2 ) * 0.05;
                // console.log('velcoity', velocity);

                // let travelTime = DISTANCE_TO_COVER / relativeVelocity;
                // let travelTime = DISTANCE_TO_COVER / velocity;
                
                // let travelTime = DISTANCE_FACTOR / (Math.abs(row[2]) + Math.abs(row[3]));
                
                // travelTime = (travelTime + 0.5) | 0; // convert to int

                // let v = ( (DISTANCE_TO_COVER/travelTime)  + 0.5) | 0;

                /*
                    I_X1 = 0;
                    I_Y1 = 1;
                    I_DIRECTION_RADIAN = 2;
                    I_U = 3;
                    I_V = 4;
                    I_NEW_X = 5;
                    I_NEW_Y = 6;
                */
                const newX = x1;
                const newY = y1;
                // console.log('u,v => ', u, v);
                let particle = [
                    x1,
                    y1,
                    dirRadian, 
                    u,
                    v,
                    newX,
                    newY
                ];
                filteredParticles.push(particle);
            }
        }
    }

    return filteredParticles;
}


export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/*
for debugging
*/
// export function drawCircleAt(lon, lat) {


//     cancelAnimationFrame(globalThis.animationRequestId); // let's pause the other animation

//     const dims = projectToXY([lon, lat])

//     globalThis.ctx.save();
// // 
//     globalThis.ctx.setTransform([1, 0, 0, 1, 0, 0]);
//     globalThis.ctx.fillStyle = 'yellow';
//     // globalThis.ctx.fillRect(dims.x, dims.y, 10, 40);


//     globalThis.ctx.beginPath();
//     globalThis.ctx.arc(dims.x, dims.y, 15, 0, Math.PI*2, false);

//     globalThis.ctx.fill();

//     globalThis.ctx.restore();

//     console.log('Circle at', dims);
// }