/** @type {HTMLCanvasElement} */

import {fetchWind} from './fetcher';
import {transformToLonLat, filterAndCalcParticleFeatures} from './helper';
import {
    I_X1, I_Y1, I_DIRECTION_RADIAN, I_TRAVEL_TIME, I_U, I_V,
    MIN_ZOOM, 
    MAX_ZOOM, 
    GRID_STEP_SIZE,
    NUM_PRECOMPUTED_GRADIENTS, 
    DISTANCE_TO_COVER,
    I_NEW_X,
    I_NEW_Y,
    PARTICLE_HEIGHT,
    PARTICLE_LENGTH,
    } from './constant';


// available through the cdn link in html file
mapboxgl.accessToken = "pk.eyJ1IjoiZW1hZGVoc2FuIiwiYSI6ImNra2wzczAwbjIyMGwyb243aTVwOGdxNDAifQ.RemLku9S5nPKpMknsrAd-w";

globalThis.map = null;
/*
payload has follwoing structure
{
    wind: [lonIdx, latIdx, u-speed, v-speed], // this will be deleted after processedData has been calculated
    res: string,
}
*/
globalThis.payload = null;
globalThis.processedData = null; // each row: [lon, lat, u, v]
globalThis.particles = null; // particle features calculated to display (currently visible) animation
globalThis.animationRequestId = null;
globalThis.doReset = true;


// const {totalWidth, totalHeight} = getPageDimensions();

const logStyleGreen = 'color: #16a085; font-size: 1.15em;';
const logStyleRed = 'color: #e74c3c; font-size: 1.15em;';


/*
saves the computation that was previously being used to compute gradient color of wind particle
by pre-computing them in advance. 
holds CanvasGradient objects. the gradients have varying colors depending on 
percentageTimePassed (which goes 0.0 to 1.0) it gives an impression of appearing 
wind particle at the start and disappearing at the end

let's say index of precomputedGradients correspond to 
percentageTimePassed (which goes 0.0 to 1.0) in some way

pick the pre-computed gradient at the corresponding index and apply it to particle fillStyle

TODO: find alternate. because updating fillStyle for each particle is expensive state changing operation
*/
globalThis.precomputedGradients = [];


/*
 create Mapbox map and align it with D3
 */
function init() {

    /*
    initiate map
    */
    globalThis.map = new mapboxgl.Map({
        container: "map", // container id
        // stylesheet location
        // style: "mapbox://styles/mapbox/light-v10",
        // style: "mapbox://styles/mapbox/dark-v10",
        style: "mapbox://styles/emadehsan/ckqw86f131b4v17s4fefsa7ut",
        // style: "mapbox://styles/mapbox/streets-v11",
        // zoom: 1, // starting zoom
        renderWorldCopies: false,
        continuousWorld: false,

        center: [71.1603, 34.1183],
        // center: [91.1603, 64.1183],

        /*
        expects: [[sw_lon, sw_lat], [ne_lon, ne_lat]]
        the map should not allow scrolling beyond these values. -85 to 85 for latitude was estimated through hit & trial
        */
        maxBounds: [
            [-180, -85],
            [180, 85]
        ],

        // mapbox zoom level can be from 0-22, 0 for zoomed-out
        minZoom: MIN_ZOOM, //
        maxZoom: MAX_ZOOM, // max zoom-in
    });


    /*
    initiate canvas
    */
    const { canvas, ctx } = getCanvasContext();
    globalThis.canvas = canvas;
    globalThis.ctx = ctx;

    /*
    pre-compute the gradients
    */
    computeGradients();

    /*
    set event listeners
    */
    globalThis.map.on("load", async () => {
        await fetchAndPrepareData();

        adjustDataStartAnimation('load');
    });

    globalThis.map.on("movestart", () => stopAnimationClearScreen('movestart'));

    globalThis.map.on("moveend", () => adjustDataStartAnimation('moveend'));


    // for debug
    // showGridLatLon();
}


function computeGradients() {
    for (let i = 0; i < NUM_PRECOMPUTED_GRADIENTS; i++) {
        const percent = i/NUM_PRECOMPUTED_GRADIENTS;

        let grd = globalThis.ctx.createLinearGradient(PARTICLE_LENGTH, 0, 0, 0); // here 12 is max wind particle width
        grd.addColorStop(0, `rgba(255, 100, 100, ${easeInQuint(percent, 1, -1, 1)})`);
        // grd.addColorStop(0.5, `rgba(220, 100, 80, ${easeInQuint(pctTimePassed, 0.8, -0.8, 1)})`);
        grd.addColorStop(1, `rgba(110, 100, 80, ${easeInQuint(percent, 0.8, -0.8, 1)})`);
        // grd.addColorStop(1, `rgba(65, 65, 65, ${easeInQuint(pctTimePassed, 0.2, -0.2, 1)})`);

        globalThis.precomputedGradients.push(grd);
    }
}


async function fetchAndPrepareData() {

    // console.log('#fetchDataAndRender called...');

    console.time('fetch-time');
    // console.log('Fetching wind data:');

    // fetch wind data from server

    // TODO use the current bounds
    const bounds = [[0,0], [359,180]];
    globalThis.payload = await fetchWind(bounds);

    /*
     convert lonIdx,latIdx in server provided data to lon,lat
     */
    globalThis.processedData = transformToLonLat(
        globalThis.payload.wind,
        globalThis.payload.res
    );


    // now delete raw data that will not be used in future
    console.log('Deleting raw data: globalThis.payload.wind');
    delete globalThis.payload.wind;

    console.timeEnd('fetch-time');
    // console.log('Wind Data', payload);
}


/*
source: https://github.com/mapbox/mapbox-gl-js/issues/10093#issuecomment-726192651
 */
function showGridLatLon() {

    if (!SHOW_LON_LAT_GRID) return;

    globalThis.map.on('load', () => {

        // if this data is inside the callback, it will be created and 
        // destroyed with the callback. no more leaking, since the 
        // listener doesn't have to hold any objects in memory after usage 
        const graticule = {
            type: 'FeatureCollection',
            features: []
        };

        for (let lng = -170; lng <= 180; lng += GRID_STEP_SIZE) {
            graticule.features.push({
                type: 'Feature',
                geometry: {type: 'LineString', coordinates: [[lng, -90], [lng, 90]]},
                properties: {value: lng}
            });
        }
        for (let lat = -80; lat <= 80; lat += GRID_STEP_SIZE) {
            graticule.features.push({
                type: 'Feature',
                geometry: {type: 'LineString', coordinates: [[-180, lat], [180, lat]]},
                properties: {value: lat}
            });
        }

        globalThis.map.addSource('graticule', {
            type: 'geojson',
            data: graticule
        });
        globalThis.map.addLayer({
            id: 'graticule',
            type: 'line',
            source: 'graticule'
        });
    });
}


function adjustDataStartAnimation(invokerName) {
    console.log(`%c#adjustDataStartAnimation: invoked by %c${invokerName} at ${new Date().getTime()}`, logStyleGreen, logStyleRed)

    /*
    on change of current view, we need to load corresponding data
    */
    if (!processedData) return;

    const bounds = getCurrentBounds();

    // set it for garbage collection
    particles = null;

    // TODO fetch & filter particles for these bounds
    particles = filterAndCalcParticleFeatures(globalThis.map, bounds);


    /* experimenting */
    // const gradientIndex = (pctTimePassed * (NUM_PRECOMPUTED_GRADIENTS - 1)) | 0; 
    // console.log(gradientIndex);
    // globalThis.ctx.fillStyle = globalThis.precomputedGradients[0];

    /*
    on the first call, pass time=0. on subsequent calls, requestAnimationFrame will pass the time
    */
    render(0);
}


/*
while dragging the map, stop and hide the animation (i.e. map will be blank)
*/
function stopAnimationClearScreen(invokerName) {
    // console.log('#stopAnimationClearScreen invoked by:', invokerName);

    // stop the animation
    if (animationRequestId) {
        cancelAnimationFrame(animationRequestId);
    }

    // clear the screen
    reset();
}


/*
 use render method to redraw the data points after zoom in / out etc.
 */
async function render(time) {
    if (!particles || !ctx)
        // the data is not loaded yet
        return;

    reset();

    drawWind(time);

    animationRequestId = requestAnimationFrame(render);
    // console.log('animationRequestId:', globalThis.animationRequestId);
}


/*
using GFS data from our APIs

tip: while reading the code below, array values indexed using constants of the form I_U or I_V
must be read without `I_`. So I_U is actually referening to U-component value of the wind in array 
*/
function drawWind(time) {
    
    if (!particles || particles.length === 0) {
        console.log('#drawWind: returning cause no particles');
        return;
    }

    globalThis.ctx.fillStyle = globalThis.precomputedGradients[0];
    
    for (let i =0; i < particles.length; i++) {

        /*
        to avoid creating hundreds of local variables on each frame
        that will then be set for Garbage Collection, let's use the 
        values at corresponding array indexes directly:
        */
        // const x1            = globalThis.particles[i][I_X1];
        // const y1            = particles[i][I_Y1];
        // const dirRadian     = particles[i][I_DIRECTION_RADIAN];
        // const travelTime    = particles[i][I_TRAVEL_TIME];
        // const u             = particles[i][I_U];
        // const v             = particles[i][I_V];
        // let newX            = particles[i][I_NEW_X];
        // let newY            = particles[i][I_NEW_Y];

        // const pctTimePassed = (time % travelTime) / travelTime;
        // const pctTimePassed = (time % particles[i][I_TRAVEL_TIME]) / particles[i][I_TRAVEL_TIME];

        // x goes from x1 -> x2
        // const x = x1 + dx * pctTimePassed; // multiply by the pctTimePassed of the path that must be covered by this "time"
        // y goes from y1 -> y2
        // const y = y1 + dy * pctTimePassed;

        // globalThis.doReset = false; // test. to check the particle trace

        // reset the frame-of-reference
        globalThis.ctx.setTransform(1, 0, 0, -1, 0, globalThis.canvas.height);
        // globalThis.ctx.setTransform(1, 0, 0, 1, 0, 0)
        // globalThis.ctx.translate(0, -300);

        // move to the starting point of this particle
        // globalThis.ctx.translate(x1, y1);
        globalThis.ctx.translate(particles[i][I_NEW_X], particles[i][I_NEW_Y]);
        // rotate the particle in direction of our movement 
        // globalThis.ctx.rotate(dirRadian);
        globalThis.ctx.rotate(particles[i][I_DIRECTION_RADIAN]);
        
        // now move the particle's x by velocity for each frame
        // globalThis.ctx.translate(newX, newY);

        // console.log('x1 -> newX', x1, newX);
        // console.log('y1 -> newY', y1, newY);

        // update
        particles[i][I_NEW_X] += particles[i][I_U];
        particles[i][I_NEW_Y] += particles[i][I_V]; 
        
        if ( 
            // Math.abs(newX-x1) > DISTANCE_TO_COVER
            // || Math.abs(newY-y1) > DISTANCE_TO_COVER
            Math.abs(particles[i][I_NEW_X] - particles[i][I_X1]) > DISTANCE_TO_COVER
            || Math.abs(particles[i][I_NEW_Y] - particles[i][I_Y1]) > DISTANCE_TO_COVER
        ) {
            // reset newX & newY back to x1,y1 (the starting position of this particle)
            particles[i][I_NEW_X] = particles[i][I_X1];
            particles[i][I_NEW_Y] = particles[i][I_Y1];
        }


        // rect_w = 18 * ( 1 - pctTimePassed); // with increase in pctTimePassed, width decreases

        // the wind particle size (width actually) goes from 0 to w and back to 0 
        // in the durange of it's travelTime

        // const maxRectW = 10; // actually rect with be half of it at full width, cause it will get multiplied by 0.5 or lower value
        // const rect_w = PARTICLE_HEIGHT;
        // const rect_w = easeOutQuint(pctTimePassed, 0, PARTICLE_HEIGHT, 1); // easeOutCubic
        // const rect_w = maxRectW * slidingScale;
        // console.log(slidingScale);
        // const rect_h = 2;
        // if (pctTimePassed < 0.5) rect_w = maxRectW * pctTimePassed + 2;
        // else rect_w = maxRectW * (1 - pctTimePassed) + 2; 

        /* 
        convert 0.0 to 0.1 value to 0.0 to 1.0 and remove decimal part
        sometimes, grandientIndex can be exatctly equalt to NUM_PRECOMPUTED_GRADIENTS, 
        to avoid that subtract 1 from NUM_PRECOMPUTED_GRADIENTS
        */
        // const gradientIndex = (pctTimePassed * (NUM_PRECOMPUTED_GRADIENTS - 1)) | 0; 
        // console.log(gradientIndex);
        // globalThis.ctx.fillStyle = globalThis.precomputedGradients[gradientIndex];  // TODO: find a trick to avoid this setting of color
        // globalThis.ctx.fillStyle = grd;

        globalThis.ctx.fillRect(0, 0, PARTICLE_LENGTH, PARTICLE_HEIGHT);
        // globalThis.ctx.restore();

        
    }
}


/**
 * @param t = current time
 * @param b = start value
 * @param c = final change in value
 * @param d = duration
 */
function easeOutQuint(t, b, c, d) {
    t /= d;
    t--;
    return c * (t * t * t * t * t + 1) + b;
}

/**
 * @param t = current time
 * @param b = start value
 * @param c = final change in value
 * @param d = duration
 */
function easeInQuint(t, b, c, d) {
    t /= d;
    return c * t * t * t * t * t + b;
}

/*
clears the screen
 */
function reset() {
    if (!globalThis.doReset) return;

    globalThis.ctx.setTransform(1, 0, 0, 1, 0, 0);
    globalThis.ctx.clearRect(0, 0, globalThis.canvas.width, globalThis.canvas.height);
}


function getCanvasContext() {
    const canvas = document.getElementById('canvasID');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    const ctx = canvas.getContext('2d');

    return {canvas, ctx};
}


/*
gets the lon,lat bounding box of current visible region of map

Mapbox standard range for lon,lat:
    lon: -180 to 180
    lat: -90 to 90

Note that if your screen allows to scroll beyond visible map, these lon,lat values might go outside this range
*/
function getCurrentBounds() {

    const bndz = globalThis.map.getBounds();

    // console.log('Bounds', bndz, new Date().getTime());

    if (!bndz || !bndz._sw || !bndz._ne) {
        console.error('#getCurrentBounds: unable to get map bndz');
        return null;
    }

    // south west is lower - left, so floor it
    const southWest = {
        lon: Math.floor(bndz._sw.lng),
        lat: Math.floor(bndz._sw.lat)
    };

    // north east is top-right, so ceil it
    const northEast = {
        lon: Math.ceil(bndz._ne.lng),
        lat: Math.ceil(bndz._ne.lat)
    };


    const bounds = [
        [southWest.lon, southWest.lat],
        [northEast.lon, northEast.lat]
    ];

    // also update the bounds displayed
    displayMapBounds(bounds);

    return bounds;
}


function displayMapBounds(bounds) {
    const [[sw_lon, sw_lat], [ne_lon, ne_lat]] = bounds;

    document.getElementById('bottomCoordBox').innerText = `${sw_lon}, ${sw_lat}`;
    document.getElementById('topCoordBox').innerText = `${ne_lon}, ${ne_lat}`;
}


init();
